$(document).ready(function() {
	if($('.js-dateStart, .js-dateEnd').length){
		// check if element is available to bind ITS ONLY ON HOMEPAGE
		var currentDate = moment().format("DD-MM-YYYY");
	
		$('.js-dateStart, .js-dateEnd').daterangepicker({
			locale: {
				  format: 'DD-MM-YYYY'
			},
			"alwaysShowCalendars": true,
			"minDate": currentDate,
			"maxDate": moment().add('years', 1),
			autoApply: true,
			autoUpdateInput: false
		}, function(start, end, label) {
		  // console.log("New date range selected: ' + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD') + ' (predefined range: ' + label + ')");
		  // Lets update the fields manually this event fires on selection of range
		  var selectedStartDate = start.format('DD-MM-YYYY'); // selected start
		  var selectedEndDate = end.format('DD-MM-YYYY'); // selected end
	
		  $checkinInput = $('.js-dateStart');
		  $checkoutInput = $('.js-dateEnd');
	
		  // Updating Fields with selected dates
		  $checkinInput.val(selectedStartDate);
		  $checkoutInput.val(selectedEndDate);
	
		  // Setting the Selection of dates on calender on CHECKOUT FIELD (To get this it must be binded by Ids not Calss)
		  var checkOutPicker = $checkoutInput.data('daterangepicker');
		  checkOutPicker.setStartDate(selectedStartDate);
		  checkOutPicker.setEndDate(selectedEndDate);
	
		  // Setting the Selection of dates on calender on CHECKIN FIELD (To get this it must be binded by Ids not Calss)
		  var checkInPicker = $checkinInput.data('daterangepicker');
		  checkInPicker.setStartDate(selectedStartDate);
		  checkInPicker.setEndDate(selectedEndDate);
	
		});
	
	} // End Daterange Picker
	
	$('.slider.slider-for').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		fade: true,
		dots: true
	});

	$('.slide-banner').slick({

		dots: false,
	
		infinite: true,
	
		speed: 300,

		arrows: false,
	
		slidesToShow: 1,
	
		slidesToScroll: 1,

		autoplay: true,
		  
		autoplaySpeed: 2000,
	
		responsive: [{
	
				breakpoint: 1024,
	
				settings: {
	
					slidesToShow: 1,
	
					slidesToScroll: 1,
	
					infinite: true,
	
					dots: false
	
				}
				
			},
	
			{
	
				breakpoint: 600,
	
				settings: {
	
					slidesToShow: 1,
	
					slidesToScroll: 1
	
				}
	
			}
	
			// You can unslick at a given breakpoint now by adding:
	
			// settings: "unslick"
	
			// instead of a settings object
	
		]
	
	});

	$('.slide-dt').slick({

		dots: false,
	
		infinite: true,
	
		speed: 300,

		arrows: false,
	
		slidesToShow: 6,
	
		slidesToScroll: 1,

		autoplay: true,
		  
		autoplaySpeed: 2000,
	
		responsive: [{
	
				breakpoint: 1024,
	
				settings: {
	
					slidesToShow: 4,
	
					slidesToScroll: 1,
	
					infinite: true,
	
					dots: false
	
				}
				
			},
	
			{
	
				breakpoint: 600,
	
				settings: {
	
					slidesToShow: 2,
	
					slidesToScroll: 1
	
				}
	
			}
	
			// You can unslick at a given breakpoint now by adding:
	
			// settings: "unslick"
	
			// instead of a settings object
	
		]
	
	});

	$('.slide-splq').slick({

		dots: false,
	
		infinite: true,
	
		speed: 300,

		arrows: false,
	
		slidesToShow: 3,
	
		slidesToScroll: 1,

		autoplay: true,
		  
		autoplaySpeed: 2000,
	
		responsive: [{
	
				breakpoint: 1024,
	
				settings: {
	
					slidesToShow: 2,
	
					slidesToScroll: 1,
	
					infinite: true,
	
					dots: false
	
				}
				
			},
	
			{
	
				breakpoint: 600,
	
				settings: {
	
					slidesToShow: 1,
	
					slidesToScroll: 1
	
				}
	
			}
	
			// You can unslick at a given breakpoint now by adding:
	
			// settings: "unslick"
	
			// instead of a settings object
	
		]
	
	});

	$('.gallery').each(function() { // the containers for all your galleries
		$(this).magnificPopup({
			delegate: 'a', // the selector for gallery item
			type: 'image',
			gallery: {
			  enabled:true
			}
		});
	});

	$(function() { //on DOM ready
        $("#scroller").simplyScroll({
            customClass: 'vert',
            orientation: 'vertical',
            auto: true,
            manualMode: 'loop',
            frameRate: 24,
            speed: 2
        });
	});
	

	// $('.slider-for').not('.slick-initialized').slick({
	// 	slidesToShow: 1,
	// 	slidesToScroll: 1,
	// 	arrows: false,
	// 	fade: true,
	// 	asNavFor: '.slider-nav'
	// });
	$('.slider-nav').slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		asNavFor: '.slider-for',
		dots: false,
		arrows: false,
		focusOnSelect: true
	});

	$(".slider-for").not('.slick-initialized').slick({
		dots: true,
		 autoplay: false,
		 autoplaySpeed: 7000,
		 arrows: false,
		 speed: 500,
		 pauseOnHover: true,
		  responsive: [
	   {
		 breakpoint: 1025,
		 settings: {
		   arrows: true,
		   dots: false
		 }
	   }
	 ]
   })
   .on('afterChange', function( e, slick, currentSlide ) {
		 $('.bumper').css('display', 'block');
		 playpausevideo($currentSlide.eq(e).data('youtubeplayer'), 'pause')
   });
	
   $('.slider-for').on('beforeChange', function(event, slick, currentSlide, nextSlide){
	var current = $(slick.$slides[currentSlide]);
	current.html(current.html());
  });

   function onYouTubeIframeAPIReady(){ // this function is called automatically when Youtube API is loaded (see: https://developers.google.com/youtube/iframe_api_reference)
	jQuery(function($){ // when DOM has loaded
		var $contentdivs = $('.sliderItem').find('div.playerID')
		$contentdivs.each(function(i){ // loop through the content divs
			var $contentdiv = $(this)
			var $youtubeframe = $contentdiv.find('iframe[src*="youtube.com"]:eq(0)') // find Youtube iframe within DIV, if it exists
			if ($youtubeframe.length == 1){
			  var player = new YT.Player($youtubeframe.get(0), { // instantiate a new Youtube API player on each Youtube iframe (its DOM object)
				events: {
				  'onReady': function(e){e.target._donecheck=true} // indicate when video has done loading
				}
			  })
				$contentdiv.data("youtubeplayer", player) // store Youtube API player inside contentdiv object
			}
		})
	})
	}
	
	function playpausevideo(player, action){
		if (player && player._donecheck === true){
			if (action == "play")
				player.playVideo()
			else if (action == "pause")
				player.pauseVideo()
		}

	}


	$('.image-popup-no-margins').magnificPopup({
		type: 'image',
		closeOnContentClick: true,
		closeBtnInside: false,
		fixedContentPos: true,
		mainClass: 'mfp-no-margins mfp-with-zoom', // class to remove default margin from left and right side
		image: {
			verticalFit: true
		},
		zoom: {
			enabled: true,
			duration: 300 // don't foget to change the duration also in CSS
		}
	});




	$('.i-hidden').click(function(event) {

		$('.main-menu').addClass('active');
	
		$('.box-fixed').addClass('active');
	
	});
	
	$('.box-fixed .bg-mod').click(function(event) {
	
		$('.main-menu').removeClass('active');
	
		$('.box-fixed').removeClass('active');
	
	});


	$('.i-hiden-page').click(function(event) {

		$('.content-left').addClass('active');
	
		$('.box-fixed').addClass('active');
	
	});
	
	$('.box-fixed .bg-mod').click(function(event) {
	
		$('.content-left').removeClass('active');
	
		$('.box-fixed').removeClass('active');
	
	});

	$('.xem-day-du').click(function(event) {

		$('.thu-gon').addClass('active');
	
		$('.xem-day-du').addClass('active');
	
		$('.content-detail-page').addClass('active');

	});
	
	$('.thu-gon').click(function(event) {
	
		$('.xem-day-du').removeClass('active');
	
		$('.thu-gon').removeClass('active');

		$('.content-detail-page').removeClass('active');
	
	});


});