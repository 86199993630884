// ADD COMMAS
function addCommas(nStr){
	nStr += '';
	x = nStr.split('.');
	x1 = x[0];
	x2 = x.length > 1 ? '.' + x[1] : '';
	var rgx = /(\d+)(\d{3})/;
	while (rgx.test(x1)) {
		x1 = x1.replace(rgx, '$1' + '.' + '$2');
	}
	return x1 + x2;
}

// QUANTITY
function quantityTrigger(button, value) {
	var type = button.data('type'),
		val = parseFloat(value.val());
		
	if (type === "plus")
		(val < 10000 && val >= 1) ? val += 1 : val = 10000;
	else if(type === "minus")
		(val > 1) ? val -= 1 : val = 1;
	value.val(val);
}
function quantityVal(value) {
	var val = value.val(),
		newVal = parseFloat(val);
						
	if(val=='' || val=='0' || isNaN(val)) {
		value.val(1);
	}
	if(newVal > 10000){
		value.val(10000);
	}
}

function smoothScroll(elem, header) {
	var targetData = $(elem).data("target"),
		// target = $(targetData),
		targetPos = $(targetData).offset().top,
		headerH = '',
		scrollTop = '';

	if(header != 0 || header != '')
		headerH = $(header).height();
	else
		headerH = 0

	scrollTop = targetPos - headerH;

	$('body, html').stop().animate({scrollTop: scrollTop}, 800);
}